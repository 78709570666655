"use client";
import "./globals.css";
import "./data-tables-css.css";
import "./satoshi.css";

import CustomSessionProvider from "@/providers/CustomSessionProvider";
import { SidebarProvider } from "@/context/SidebarContext";
import Script from "next/script";
import { sendNewFundraiserEvent } from "./lib/ga4";

const MEASUREMENT_ID = process.env.NEXT_PUBLIC_MEASUREMENT_ID;
let flag = false;

// Declare the dataLayer property on the Window interface
declare global {
  interface Window {
    dataLayer: any[]; // You can specify the appropriate type for your dataLayer
  }
}

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <CustomSessionProvider>
      <SidebarProvider>
        <html lang="en">
          <head>
            {MEASUREMENT_ID && (
              <Script
                src={`https://www.googletagmanager.com/gtag/js?id=${MEASUREMENT_ID}`}
                strategy="lazyOnload"
              />
            )}
            {MEASUREMENT_ID && (
              <Script
                id="show-banner"
                dangerouslySetInnerHTML={{
                  __html: `
                  window.dataLayer = window.dataLayer || [];
                  dataLayer = window.dataLayer;
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());

                  gtag('config', '${MEASUREMENT_ID}');
                  `,
                }}
              />
            )}
            <link
              rel="icon"
              type="image/png"
              href="/favicon-96x96.png"
              sizes="96x96"
            />
            <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
            <link rel="shortcut icon" href="/favicon.ico" />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/apple-touch-icon.png"
            />
            <meta name="apple-mobile-web-app-title" content="Trillage" />
            <link rel="manifest" href="/site.webmanifest" />
          </head>
          <body suppressHydrationWarning={true}>{children}</body>
        </html>
      </SidebarProvider>
    </CustomSessionProvider>
  );
}
