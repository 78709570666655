import React, { useState, createContext, useContext } from "react";

const SidebarContext = createContext({});

const SidebarProvider = ({ children }: { children: React.ReactNode }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [sidebarOptions, setSidebarOptions] = useState([]);

  const value = {
    showSidebar,
    setShowSidebar,
    sidebarOptions,
    setSidebarOptions,
  };

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
};

const useSidebar = () => {
  return useContext(SidebarContext);
};

export { SidebarProvider, useSidebar };
